export default {
  c1: `
    @Mixin
    class MessageMixin extends Vue {
      private someValue: number = 1
    
      async userMessages() {
       await this.$axios.$post('/our/pretty/url/')
         .then(r =>  this.$store.commit('setMessage', r))
         .catch(e => /* bugsnag / sentry / etc */)
      }
    }
  `,

  c2: `
    class MyComponent extends Mixins<MixinInterface>(
      MessageMixin,
      NotificationMixin
    ) {
  `,

  c3: `
    export default {
      data: () => ({
        someValue: 1
      }),

      methods: {
        async userMessages() {
          await this.$axios.$post('/our/pretty/url/')
            .then(r =>  this.$store.commit('setMessage', r))
            .catch(e => /* bugsnag / sentry / etc */)
         }
      }
    }
  `,

  c4: `
    name: 'MyComponent',

    mixins: [
      MessageMixin,
      NotificationMixin
    ]
  `
}
