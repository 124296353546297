<template>
  <div class="ra-basic ra-basic-text">
    <h2>Mixins - elegant work with API</h2>
    <p>
      And why you no longer need <b>Vuex.actions</b>
    </p>
    <br />
    <br />
    <div>
      In classic example in <b>Vue.js</b> <b>SPA/SSR</b> app,
      work with <b>API</b> mostly looking
      like <b>Vuex actions</b>,
      and from this, in my opinion,
      core problem in <b>medium</b> and <b>big</b> Vue.js projects
      <br />
      I will describe why
      <br />
      <br />
      When we use <b>Vuex.actions + modules</b>,
      to interact with our <b>API</b>,
      <br />
      every module looks like:
      <ul>
        <li><b>state</b>- 10 lines</li>
        <li><b>mutations</b>- 10 lines</li>
        <li><b>actions</b>- 600 lines</li>
      </ul>
      and its not right way to use <b>Vuex</b>
      <br />
      <br />
      I fight against this "architecture" since 2016
      and have lot of disputs and objections with other developers in my teams
      <br />
      As result of this years, finally,
      I have a good solution for working with <b>API</b>
      <br />
      <br />
      so... <i>drumroll</i>
      <br />
      <img src="/articles/4/drum.png">
      <br />
      <h3>Mixins</h3>
      <h4>Why exactly it is better than actions?</h4>
      <ul class="ra-ul-numbered">
        <li>
          <RaQuote>
            It is a lot of small mixins
            instead of big Vuex modules
            <br />
            <b>(better code maintenance)</b>
          </RaQuote>
        </li>
        <li>
          <RaQuote>
            Mixin loading only in component where needed
            <br />
            <b>(memory matter)</b>
          </RaQuote>
        </li>
        <li>
          <RaQuote>
            You can use 2+ similar mixin for different component,
            with your custom data state, and it is unique
            <b>wrapper</b> for every component
          </RaQuote>
        </li>
        <li>
          <RaQuote>
            Easily extensible and fully support
            all the features of <b>Vue.js</b>
            <br />
            such as
            <b>Computed, Watcher, Data, Methods</b>,
            all of <b>Lifecycle Hooks</b>
            and others
          </RaQuote>
        </li>
        <li>
          <RaQuote>
            Can be inherited and extensible from each other
          </RaQuote>
        </li>
      </ul>
      <br />
      <RaCode>
        {{ code.c1 }}
      </RaCode>
      <br />
      then, easly injectable lot of different mixins in component:
      <RaCode>
        {{ code.c2 }}
      </RaCode>
      <br />
      or examaple for not TypeScript friendly guys:
      <RaCode>
        {{ code.c3 }}
      </RaCode>
      and inject
      <RaCode>
        {{ code.c4 }}
      </RaCode>
      <br />
      If you are coming to <b>Vue</b> from <b>React</b>,
      nearest analogue is <b>HOC</b>,
      but in Vue it is realised better (I hate HOCs in React by the way)
      <br />
      <br />
      <b>In summary:</b>
      <br />
      I <b>highly</b> recomend to use <b>Mixins</b> for work with <b>API</b>,
      but ultimately the choice is yours
      <br />
      <br />
      stay tuned
      <br />
      Bye bye
    </div>
    <br />
    <p>
      {{ authors.length > 1 ? 'Authors:' : 'Author:' }}
      <RaLink
        v-for="(author, key) in authors"
        :key="key"
        :link="author.link"
        follow
        styled
      >
        {{ author.nick }}
      </RaLink>
    </p>
    <div
      v-if="vote && vote.up >= 0 && vote.down >= 0"
      class="ra-vote-block"
    >
      <RaButton
        v-title="`${vote.up} votes`"
        vote
        vote-type="up"
        type="success"
        class="ra-vote-button"
        @click="makeVote(postId, true)"
      />
      <RaButton
        v-title="`${vote.down} votes`"
        vote
        vote-type="down"
        type="danger"
        class="ra-vote-button"
        @click="makeVote(postId, false)"
      />
    </div>
  </div>
</template>

<script>
import VTitle from 'v-title'

import RaCode from '@/components/elements/RaCode.vue'
import RaLink from '@/components/elements/RaLink.vue'
import RaButton from '@/components/elements/RaButton.vue'
import RaQuote from '@/components/elements/RaQuote.vue'

import fingerVote from '@/mixins/fingerVote'

import codeData from '@/components/articles/Mixins/code'

export default {
  name: 'Ordering',

  components: {
    RaCode,
    RaLink,
    RaButton,
    RaQuote
  },

  directives: {
    title: VTitle
  },

  mixins: [fingerVote],

  metaInfo: {
    title: 'Mixins - elegant work with API',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'And why you no longer need Vuex.actions'
      },
      {
        name: 'og:title',
        content: 'Mixins - elegant work with API'
      },
      {
        name: 'twitter:title',
        content: 'Mixins - elegant work with API'
      },
      {
        name: 'og:description',
        content: 'And why you no longer need Vuex.actions'
      },
      {
        name: 'twitter:description',
        content: 'And why you no longer need Vuex.actions'
      },
      { name: 'og:url', content: 'https://ralmaz.pro/blog/mixins' },
      {
        name: 'twitter:url',
        content: 'https://ralmaz.pro/blog/mixins'
      },

      { name: 'og:type', content: 'article' },
      { name: 'article:published_time', content: '2020-06-30' },
      { name: 'article:modified_time', content: '2020-07-02' },
      { name: 'article:author', content: 'RALMAZ' },
      { name: 'article:section', content: 'Vue.js' },
      { name: 'article:tag', content: 'Vue.js' },
      { name: 'article:tag', content: 'JavaScript' }
    ]
  },

  data: () => ({
    postId: 4,
    createdAt: '2020-06-30',
    authors: [
      {
        nick: '@RALMAZ',
        link: 'https://ralmaz.pro'
      }
    ]
  }),

  computed: {
    code() {
      return codeData
    }
  },

  mounted() {
    this.initFingerVote(this.postId)
  }
}
</script>
